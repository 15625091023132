$phone: 767px;
$tablet: 1016px;

$black: #050605;
$green: #CDDB00;

@font-face {
	font-family: 'proxima_nova_altlight';
	src: url('/fonts/mark_simonson_-_proxima_nova_alt_light-webfont.eot');
	src: url('/fonts/mark_simonson_-_proxima_nova_alt_light-webfont.eot?#iefix') format('embedded-opentype'),
	     url('/fonts/mark_simonson_-_proxima_nova_alt_light-webfont.woff2') format('woff2'),
	     url('/fonts/mark_simonson_-_proxima_nova_alt_light-webfont.woff') format('woff'),
	     url('/fonts/mark_simonson_-_proxima_nova_alt_light-webfont.ttf') format('truetype'),
	     url('/fonts/mark_simonson_-_proxima_nova_alt_light-webfont.svg#proxima_nova_altlight') format('svg');
	font-weight: 300;
	font-style: normal;
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	background: $black url('/images/background.png') top 50px center no-repeat;
	background-size: 1832px 1054px;
	color: #FFF;
	font-family: 'proxima_nova_altlight', 'Helvetica', 'Arial', sans-serif;
	font-weight: 300;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 300;
	color: $green;
	text-transform: lowercase;
	margin-bottom: 0.625rem;
}

h1, h2 {
	font-size: 1.5625em;
}

h3 {
	font-size: 1.125em;
	margin-top: 0.625rem;
}

p {
	font-size: 0.8125em;
	line-height: 1rem;
	margin-top: 0;
	margin-bottom: 0.625rem;
}

a {
	color: #FFF;
}

.read-more {
	&__btn {
		color: $green;
		background: none;
		padding: 0;
		margin: 0;
		border: 0;
	}

	&__text {
		display: none;

		&--visible {
			display: block;
		}
	}
}

.btn {
	display: inline-block;
	background: $green;
	border: 1px solid #FFF;
	color: #FFF;
	text-transform: lowercase;
	text-decoration: none;
	padding: 7px 30px;

	&:hover,
	&:focus {
		background: #FFF;
		color: $green;
	}
}

.img-right {
	img {
		@media (max-width: $tablet) {
			width: 48.036253776%;
		}

		margin-left: auto;
	}
}

.bottom-left {
	display: flex;
	align-items: flex-end;

	img {
		@media (max-width: $phone) {
			margin-left: 20px;
		}

		margin-bottom: 20px;
		width: 74px;
	}
}

.padded {
	padding: 0 15px;
}

.green-background {
	background: $green;
}

.container {
	width: 100%;
	max-width: 1017px;
	margin: 0 auto;
}

.header {
	@media (max-width: $phone) {
		flex-direction: column;
		margin: 20px 0;
	}

	display: flex;
	justify-content: space-between;
	margin: 50px 0 30px;

	&__logo {
		@media (max-width: $phone) {
			margin: 0 auto 20px;
		}

		display: block;

		img {
			@media (max-width: 767px) {
				margin: 0 auto;
			}
		}
	}

	&__phone,
	&__email {
		display: block;
		text-decoration: none;
		text-align: center;
	}

	&__phone {
		font-size: 1.6875em;
		color: $green;
	}

	&__email {
		font-size: 0.75em;
	}
}

main {
	img {
		@media (max-width: $phone) {
			width: 100%;
		}
	}

	.img-right {
		img {
			@media (max-width: $phone) {
				width: 159px;
			}
		}
	}
}

.row {
	@media (max-width: $phone) {
		flex-direction: column;
	}

	display: flex;
	justify-content: space-between;
}

.third,
.two-thirds {
	margin-bottom: 12px;
}

@media (min-width: $phone+1) {
	.third {
		width: ((331 / 1017) * 100%);
	}

	.two-thirds {
		width: ((674 / 1017) * 100%);
	}	
}

.contact {
	&__field {
		display: block;
		background: rgba(#FFF, 0.4);
		border: 0;
		margin-bottom: 10px;
		width: 100%;
		padding: 10px;
	}

	&__hide {
		display: none;
	}

	::-webkit-input-placeholder {
		color: #FFF;
		text-transform: lowercase;
	}

	::-moz-placeholder {
		color: #FFF;
		text-transform: lowercase;
	}

	:-ms-input-placeholder {
		color: #FFF;
		text-transform: lowercase;
	}

	:-moz-placeholder {
		color: #FFF;
		text-transform: lowercase;
	}
}

.footer {
	@media (min-width: $phone+1) {
		background: linear-gradient(to right, transparent 50%, $green 50%);
	}

	margin-top: 18px;

	.third {
		margin-bottom: 0;
	}

	.padded {
		padding: 20px;
	}

	h2 {
		color: #FFF;
		margin-top: 0;
		margin-bottom: 0;
	}

	&__title {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;

		img {
			margin-right: 10px;
		}
	}

	&__last {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__logos {
		display: flex;
		align-items: flex-end;

		img {
			margin-right: 30px;
		}
	}
}

.copyright {
	margin-left: auto;
	padding: 0 20px 10px;
}
